import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { lazy } from "react";
import { HelmetProvider } from "react-helmet-async";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import translationEN from "./locales/en.json";
import translationHU from "./locales/hu.json";

const Navbar = lazy(() => import("./components/Navbar"));
const Footer = lazy(() => import("./components/Footer"));
const Home = lazy(() => import("./pages/home/Home"));
const Create = lazy(() => import("./pages/create/Create"));
const About = lazy(() => import("./pages/About"));
const Page404 = lazy(() => import("./pages/Page404"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const CookieNotifier = lazy(() => import("./components/CookieNotifier"));

const queryClient = new QueryClient();

const resources = {
  en: {
    translation: translationEN,
  },
  hu: {
    translation: translationHU,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  if (localStorage.getItem("lang")) {
    i18n.changeLanguage(localStorage.getItem("lang"));
  }

  return (
    <div className="App">
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Navbar />
            <Routes>
              <Route index element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/create" element={<Create />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route
                path="/termsandconditions"
                element={<TermsAndConditions />}
              />
              <Route path="/cookiepolicy" element={<CookiePolicy />} />
              <Route path="*" element={<Page404 />} />
            </Routes>
            <CookieNotifier />
            <Footer />
          </Router>
        </QueryClientProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
